<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {dateUtil} from "@/helpers/date-util";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import {required} from "vuelidate/lib/validators";

import Switches from "vue-switches";

export default {
  components: {
    Layout,
    PageHeader,
    Switches
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,
      languageId: "",

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        isBusy: false,

        items: []
      },

      filtering: {
        showDeleted: false
      },

      modals: {
        customPath: {
          visible: false,
          form: {}
        }
      }

    };
  },

  validations: {
    modals: {
      customPath: {
        form: {
          path: { required },
        }
      }
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: "Własne ścieżki",
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "path", label: "Ścieżka"},
        { key: "type", label: "Typ", formatter: value => this.$t(`custom-path.path-type.${value}`)},
        { key: "contentType", label: "Typ pliku", formatter: value => this.$t(`custom-path.content-type.${value}`)},
        { key: "archived", slot: true, label: "Zarchiwizowane" },
        { key: "createdAt", label: "Data utworzenia", formatter: value => dateUtil.asDateTime(value) },
        { key: "archivedAt", label: "Data archiwizacji", formatter: value => dateUtil.asDateTime(value) },
        { key: "action", slot: true, label: this.$t('table.actions') }
      ]
    },

    async loadCustomPaths() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const { data } = await axios.get(`/custom-path/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          page: page,
          size: this.table.perPage,
          languageId: this.languageId,
          ...this.filtering
        }
      });

      const { customPaths, count } = data

      this.table.items = customPaths
      this.table.totalRows = count
      this.table.rows = count

      return this.table.items;
    },

    openCustomPathModal(customPath) {
      window.location = `/dashboard/admin/custom-paths/create-or-edit/${this.languageId}` + (customPath ? `?id=${customPath.id}` : '')
    },

    hideCustomPathModal() {
      this.modals.customPath.visible = false
      this.modals.customPath.form = {}
    },

    createOrEditCustomPath() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.modals.customPath.form.$touch();
      if (this.$v.modals.customPath.form.$invalid) {
        return;
      }

      const json = JSON.stringify({
        languageId: this.languageId,
        ...this.modals.customPath.form
      });

      axios.put(`/custom-path`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.hideCustomPathModal()
        Swal.fire("Sukces!", this.modals.customPath.form.id ? "Zaktualizowano scieżke" : "Dodano nową ścieżke", "success")
            .then(() => this.$refs.table.refresh())
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    deleteCustomPath(customPath) {
      swalHelper.yesOrNoCustomizable(() => {
        axios.delete(`/custom-path/${customPath.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        }).then(() => {
          Swal.fire("Sukces!", "Scieżka została usuniętą!", "success")
              .then(() => this.$refs.table.refresh());
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, "warning", "Czy na pewno?", "Scieżka zostanie usuniętą", "Usuń", "Anuluj", "btn btn-danger", "btn btn-secondary")
    }
  },

  async created() {
    const id = this.$route.params.id;
    if (!id) {
      await this.$router.push('/dashboard/home').then(() => {
        Swal.fire("", "Nie znaleziono takiego języka!", "error");
      }).catch((error) => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.log(error)
        }
      })
      return;
    }

    this.languageId = id
  }

};
</script>

<template>
  <Layout>
    <PageHeader title="Własne ścieżki" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="button-items mb-4">
              <b-button variant="success" @click="openCustomPathModal(null)">Dodaj nową ścieżke</b-button>
            </div>

            <div class="row">
              <custom-card-subtitle title="Filtrowanie"/>

              <div class="col-lg-2">
                <div class="d-flex flex-sm-column">
                  <label class="font-size-13">
                    Pokazuj usunięte:
                    {{ filtering.showDeleted ? "Tak" : "Nie" }}
                  </label>

                  <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh" v-model="filtering.showDeleted"
                            class="my-0" color="danger"
                  />
                </div>
              </div>
            </div>

            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadCustomPaths"
                :pagination-top="true">
              <template v-slot:archived="{ item }">
                <span :class="item.archived ? 'badge badge-soft-success' : 'badge badge-soft-danger'" class="font-size-11">{{ $t(item.archived ? 'message.yes' : 'message.no') }}</span>
              </template>

              <template v-slot:action="{ item }">
                <div class="button-items">
                  <b-button variant="primary" class="btn-sm" @click="openCustomPathModal(item)">Edytuj</b-button>
                  <b-button v-if="!item.deleted" variant="danger" class="btn-sm" @click="deleteCustomPath(item)">Usuń</b-button>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.customPath.visible"
        :title="modals.customPath.form.id ? 'Edytowanie ścieżki' : 'Dodawanie nowej ścieżki'"
        title-class="font-18"
        hide-footer
        @hide="hideCustomPathModal"
        @esc="hideCustomPathModal">
      <div class="form-group">
        <label>Ścieżka</label>
        <input v-model="modals.customPath.form.path" type="text" class="form-control" />
      </div>

      <div class="pdf-container"></div>

      <div class="text-right">
        <b-button @click="createOrEditCustomPath" variant="success">{{ modals.customPath.form.id ? 'Edytuj' : 'Utwórz' }}</b-button>
        <b-button class="ml-1" variant="danger" @click="hideCustomPathModal">{{  $t('message.cancel') }}</b-button>
      </div>
    </b-modal>

  </Layout>
</template>

<style scoped>
.pdf-container {
  height: 100vh;
}
</style>